<template>
  <div class="tw-flex tw-items-center" :class="$attrs.class">
    <prime-checkbox
      :input-id="inputId"
      :binary="binary"
      :disabled="disabled"
      :name="name"
      :value="value"
      :pt="{
        root: ['de-form-checkbox', { 'is-checked': checked }, props.inputClass],
        input: 'de-form-checkbox-input',
      }"
      @click="onChange"
    >
      <template #icon>
        <de-svg-icon v-if="checked" name="check" class="de-form-checkbox-icon" />
      </template>
    </prime-checkbox>

    <label v-if="inputId" :for="inputId" class="de-form-checkbox-label" :class="props.labelClass">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>

  <div v-if="showError && errorMessage" class="de-form-error">
    {{ errorMessage }}
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRefs } from '#imports';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';

const props = defineProps<{
  value: boolean | string;
  name: string;
  binary?: boolean;
  inputId?: string;
  label?: string;
  inputClass?: string;
  labelClass?: string;
  disabled?: boolean;
  showError?: boolean;
  beforeChange?: (isChecked: boolean) => boolean;
}>();

const { name } = toRefs(props);

const {
  checked,
  handleChange,
  errorMessage,
  value: checkboxValue,
} = useField(name, undefined, {
  // 👇 These are important
  type: 'checkbox',
  checkedValue: props.value,
});

const emit = defineEmits<{
  (event: 'change', value: string | boolean, isChecked: boolean): void;
}>();

const onChange = () => {
  if (props.beforeChange && typeof props.beforeChange === 'function') {
    const isProceed = props.beforeChange(!!checked?.value);
    if (isProceed) {
      handleChange(props.value);

      emit('change', checkboxValue.value, !!checked?.value);
    }

    return;
  }

  handleChange(props.value);
  emit('change', checkboxValue.value, !!checked?.value);
};
</script>
